<template>
  <div class="bu-ju-3">
    <div class="top">
      <header-selete placeholder="搜索手机号、用户昵称" @submit="getLoad" ref="souSuo"/>
    </div>
    <div class="body bg-aaa">
      <div class="user-item" v-for="(vo,index) in dataList" :key="vo.id">
        <div class="user-image">
          <van-image width="56" height="56" :src="vo.avatar"/>
        </div>
        <div class="user-info">
          <div class="user-name van-ellipsis">{{ vo.user_nickname }}</div>
          <div class="user-mobile van-ellipsis">手机：{{ vo.mobile }}</div>
          <div class="user-create-time van-ellipsis">
            <div>
              {{ getTimeName(vo.sq_status) }}：{{ vo.create_time }}
            </div>
            <div>
              <a href="javascript:"
                 @click="setStatus(index,voBtn)"
                 v-for="(voBtn,index2) in vo.btn"
                 :key="index2"
                 class="edit-btn"
                 :class="voBtn.class"
              >
                {{ voBtn.label }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <footer-nav/>
    </div>
  </div>
</template>

<script>
import HeaderSelete from "../open/headerSelete";
import FooterNav from "../open/footerNav";
import http from "../../api/http";
import {Dialog} from 'vant'
import din_shi from "../../api/din_shi";
import XEUtils from "xe-utils";
import moment from 'moment'
import {hidden} from "../../api/GongJu";

export default {
  name: 'YuanGongGuanLi',
  components: {FooterNav, HeaderSelete},
  mixins: [],
  data() {
    return {
      where: {},
      dataList: []
    }
  },
  mounted() {
    this.getLoad()
  },
  methods: {

    getTimeName(status) {
      if (status === '通过') return '加入时间'
      else return '申请时间';
    },
    getLoad() {

      this.where.keyword = this.$refs.souSuo.getKeyword()
      http.get('/gzhphp/user/getYuanGongList', {params: this.where}).then(da => {
        this.dataList = da.data.map(n => {
          n.btn = this.getEditBtn(n)
          n.create_time = moment(n.create_time * 1000).format('YYYY-MM-DD')
          n.mobile = hidden(n.mobile,3,4)
          return n
        })
      })
    },
    setStatus(index, btnItem) {
      let item = this.dataList[index]
      Dialog.confirm({
        title: '提示',
        message: `确定要${btnItem.type}`,
      }).then(() => {
        http.post("/gzhphp/user/editYuanGongShenQing", {id: item.l_id, type: btnItem.type}).then(da => {
          if (da.code === 1) {
            this.dataList[index].btn = this.getEditBtn(da.data)
            din_shi.success(da.msg)
          }
        })
      })
    },
    getEditBtn(item) {
      if (XEUtils.isNull(item)) return []
      if (item.sq_status === '申请中') {
        return [
          {
            label: '同意加入',
            type: '通过',
            class: 'tong-guo',
          },
          {
            label: '拒绝加入',
            type: '拒绝',
            class: 'ju-jue',
          }
        ]
      }

      if (item.sq_status === '通过') {
        return [
          {
            label: '停用员工',
            type: '停用',
            class: 'ting-yong',
          }
        ]
      }

      if (item.sq_status === '停用') {
        return [
          {
            label: '启用员工',
            type: '通过',
            class: 'tong-guo',
          }
        ]
      }
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.user-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  background-color: @white;
  box-shadow: @yin-ying;
  padding: 10px;
  border-radius: 10px;

  .user-image {
    width: 70px;
  }

  .user-info {
    flex: 1;
    font-size: 14px;

    .user-name {
      font-size: 16px;
    }

    .user-create-time {
      color: @gray-6;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.edit-btn {
  margin: 0 3px;

  &.tong-guo {
    color: @gray-7;
  }

  &.ju-jue {
    color: @pei-se;
  }

  &.ting-yong {
    color: @red;

  }
}
</style>
